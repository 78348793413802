// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
require("channels")
require('jquery')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// require('css/application.scss')

//import CodeMirror from 'codemirror';
import CodeMirror from 'codemirror/lib/codemirror.js';
require('codemirror/lib/codemirror.css');
require('codemirror/theme/darcula.css');
import 'codemirror/keymap/sublime.js';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/mode/ruby/ruby.js';
import 'codemirror/mode/htmlembedded/htmlembedded.js';
import 'codemirror/addon/edit/closebrackets.js';
import 'codemirror/mode/css/css.js';
import 'codemirror/mode/sass/sass.js';
import 'codemirror/addon/edit/closebrackets.js';
import 'codemirror/addon/edit/closetag.js';
import 'codemirror/addon/edit/matchbrackets.js';
import 'codemirror/addon/edit/matchtags.js';
import 'codemirror/addon/runmode/colorize.js'

$(document).ready(function (){
  $('.code-mirror-editor').each(function() {
    var textArea = document.getElementById($(this).attr("id"));

    var myCodeMirror = CodeMirror.fromTextArea(textArea, {
      mode:  textArea.dataset.mode,
      keyMap: "sublime",
      theme: "darcula",
      tabSize: 2,
      lineNumbers: true,
      lineWrapping: true,
      autoCloseBrackets: true,
      matchBrackets: true,
      matchTags: true,
      autoCloseTags: true,
      onChange: function(){editor.save()}
    });
  });

  if ($('pre.colorize-code').length > 0) {
    CodeMirror.colorize();
  }
});