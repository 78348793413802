import consumer from "./consumer"
import $ from 'jquery';

$(document).ready(function() {
  consumer.subscriptions.create("VenuesChannel", {
    connected() {
      // Called when the subscription is ready for use on the server
      console.log("Venues channel connected")
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel
      console.log(data)

      if ((data["reason"] === "updated"))
      {
        // && $('.subscribed-to-venue-updates#' + data["element_id"]).length > 0
        $('.subscribed-to-venue-updates#' + data["element_id"]).each(function() {
          $(this).parent().load($(this).data('refresh-url'), function() {
             console.log('Data refreshed');
          });
        });
      }

    }
  });
});